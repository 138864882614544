import { lazy, Suspense } from 'react';
import { Route, Routes } from "react-router-dom";
// Custom Components
import Home from "./pages/Home/Home";
const Contact = lazy(() => import('./pages/Contact/Contact'));
const Faq = lazy(() => import('./pages/Faq'));
const AllProducts = lazy(() => import('./pages/Products/AllProducts'));
const ProductPage = lazy(() => import('./pages/ProductPage/ProductPage'));
const Cart = lazy(() => import('./pages/Cart/Cart'));
const Favourites = lazy(() => import('./pages/Favourites/Favourites'));
const MissingPage = lazy(() => import('./pages/MissingPage'));
const About = lazy(() => import('./pages/About/About'));
const PrevOrders = lazy(() => import('./pages/PrevOrders/PrevOrders'));

function Router()
{
	return (
		<Routes>
			<Route exact path='/' element={<Home />} />
			<Route exact path='/contact' element={<Suspense fallback={<div style={{ minWidth: "100vh" }}></div>}><Contact /></Suspense>} />
			<Route exact path='/faq' element={<Suspense fallback={<div style={{ minWidth: "100vh" }}></div>}><Faq /></Suspense>} />
			<Route exact path='/products' element={<Suspense fallback={<div style={{ minWidth: "100vh" }}></div>}><AllProducts /></Suspense>} />
			<Route exact path='/products/:id' element={<Suspense fallback={<div style={{ minWidth: "100vh" }}></div>}><ProductPage /></Suspense>} />
			<Route exact path='/cart' element={<Suspense fallback={<div style={{ minWidth: "100vh" }}></div>}><Cart /></Suspense>} />
			<Route exact path='/favourites' element={<Suspense fallback={<div style={{ minWidth: "100vh" }}></div>}><Favourites /></Suspense>} />
			<Route exact path='/about' element={<Suspense fallback={<div style={{ minWidth: "100vh" }}></div>}><About /></Suspense>} />
			<Route exact path='/prevOrders' element={<Suspense fallback={<div style={{ minWidth: "100vh" }}></div>}><PrevOrders /></Suspense>} />
			<Route path='*' element={<Suspense fallback={<div style={{ minWidth: "100vh" }}></div>}><MissingPage /></Suspense>} />
		</Routes>
	);
}

export default Router;
