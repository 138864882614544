import { lazy, Suspense } from 'react';
const PhotoCarousel = lazy(() => import('../../components/UtilityComponents/Carousel'));
const FeaturedProducts = lazy(() => import('../../components/UtilityComponents/FeaturedProducts/FeaturedProducts'));
const ShopByCategoryContainer = lazy(() => import('../../components/UtilityComponents/ShopByCategory/ShopByCategoryContainer'));
const Features = lazy(() => import('../../components/UtilityComponents/Features/Features'));
const UniqueFeatures = lazy(() => import('../../components/UtilityComponents/UniqueFeatures'));
const Helmet = lazy(() => import('react-helmet'));

function Home()
{
	const websiteSchema = {
		"@context": "https://schema.org",
		"@type": "WebSite",
		name: "Sugandha Arts",
		description: "Explore exquisite metal-based handicrafts at Sugandha Arts. Discover unique wall decor, hangings, table pieces and clocks meticulously crafted with fine artistry. Based in Jodhpur, India, our products showcase rich heritage and uncompromising quality. Experience the beauty of our metal-based creations today.",
		url: "https://www.sugandhaarts.com",
		image: "https://www.sugandhaarts.com/logo.png",
		sameAs: [
			"https://www.facebook.com/SugandhaArtsHandicrafts",
			"https://www.instagram.com/sugandhaarts_handicrafts",
			"https://www.twitter.com/SugandhaArts"
		],
		potentialAction: {
			"@type": "SearchAction",
			target: "https://www.sugandhaarts.com/products/{search_term_string}",
			"query-input": "required name=search_term_string",
		},
		contactPoint: [
			{
				"@type": "ContactPoint",
				telephone: "+919828610410",
				email: "manishthukral.jodh@gmail.com",
				contactType: "Owner",
				availableLanguage: ["English", "Hindi", "Marwadi"],
			},
		],
		mainEntityOfPage: {
			"@type": "Products Page",
			"@id": "https://www.sugandhaarts.com/products",
		},

		headline: "Sugandha Arts - Metal-based handicrafts, Wall Decor, Showpieces and fancy wall clocks",
		publisher: {
			"@type": "Organization",
			name: "Sugandha Arts",
			logo: {
				"@type": "ImageObject",
				url: "https://www.sugandhaarts.com/logo.png",
			},
		},
	};
	return (
		<Suspense fallback={<div style={{ "minHeight": "120vh" }}></div>}>
			<title>Sugandha Arts - Metal-based handicrafts Wall Decor, Showpieces and fancy wall clocks</title>
			<Helmet>
				<script type='application/ld+json'>{JSON.stringify(websiteSchema)}</script>
				<link rel="canonical" href="https://www.sugandhaarts.com" />
			</Helmet>
			<PhotoCarousel />
			<FeaturedProducts />
			<ShopByCategoryContainer />
			<Features />
			<UniqueFeatures />
		</Suspense>
	);
}

export default Home;
