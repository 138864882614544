// React Components
import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

// Custom Components
import CartContext from "../../contexts/CartContext";
import FavouriteContext from "../../contexts/FavouriteContext";
import { Login, Logout } from "../UtilityComponents/Firebase";
// Libraries
import { Offcanvas, Navbar, Nav, Container, Row, Col } from "react-bootstrap";
import { Button, Dropdown, Select } from "antd";
// Stylesheets
import "../../styles/global.css";
import "./Navbar.css";
// Icons
import { UserOutlined, HistoryOutlined } from "@ant-design/icons";
import { MdOutlineShoppingCart, MdShoppingCart, MdOutlineFavoriteBorder, MdOutlineFavorite } from "react-icons/md";
import SugandhaArtsDetails from "../../_data/SugandhaArtsDetails";
import { FcGoogle } from "react-icons/fc";
// Context
import UserContext from "../../contexts/UserContext";
//Data
import productsJSON from "../../_data/Products.json";
const { Option } = Select;


function NavBar(props)
{
	const { cart } = useContext(CartContext);
	const { user, setUser } = useContext(UserContext);
	const { favourite } = useContext(FavouriteContext);
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const onSearchSelect = (value) =>
	{
		navigate("/products/" + value);
	};

	const generateSearchOptions = () =>
	{
		return productsJSON.map((product) => (
			<Option key={product.id} value={product.id}>
				{`${product.name} [${product.id}] (${product.category})`}
			</Option>
		));
	};

	const handleMenuClick = (e) =>
	{
		switch (e.key)
		{
			case "1":
				// navigate("/prevOrders");
				break;

			case "2":
				Logout(setUser);
				break;
		}
	};
	const items = [
		{
			label: user.name,
			key: "0",
			disabled: false,
		},
		{
			label: "View Previous Orders",
			key: "1",
			icon: <HistoryOutlined />,
			disabled: true,
		},
		{
			label: "Logout",
			key: "2",
			icon: <UserOutlined />,
			danger: true,
		},
	];
	const menuProps = {
		items,
		onClick: handleMenuClick,
	};

	return (
		<Navbar expand='lg' style={{ backgroundColor: "var(--sky-blue)", position: 'sticky', top: 0, zIndex: 1 }} variant='light' collapseOnSelect={true} className='bottom-rounded'>
			<Container fluid>

				<Navbar.Brand as={Link} to='/' className='heading brand-name navbar-brand' title='Home Page - Sugandha Arts - Metal-based handicrafts Wall Decor, Showpieces and fancy wall clocks'>
					<span className='heading brand-name'>{SugandhaArtsDetails.name}</span>
				</Navbar.Brand>
				<Navbar.Offcanvas placement='start' style={{ backgroundColor: "var(--sky-blue)", maxWidth: '400px', margin: 0 }} id={`offcanvasNavbar-expand-lg`} aria-labelledby={`offcanvasNavbarLabel-expand-lg`}>
					<Offcanvas.Header closeButton className='me-2'>
						<Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
							<span className='heading brand-name'>{SugandhaArtsDetails.name}</span>
						</Offcanvas.Title>
					</Offcanvas.Header>
					<Offcanvas.Body className='' >
						<Nav>
							<Link to="/" className='my-3 mx-2' style={{ textDecoration: "none" }} title='Home Page - Sugandha Arts - Metal-based handicrafts Wall Decor, Showpieces and fancy wall clocks'>
								<span className={`navbar-link ${pathname === '/' ? "active" : ""}`} >
									Home
								</span>
							</Link>
							<Link to="/products" className='my-3 mx-2' style={{ textDecoration: "none" }} title='All Products - Buy Handicraft Wall Decor, Showpieces, Clocks'>

								<span className={`navbar-link ${pathname === '/products' ? "active" : ""}`} >
									Products
								</span>
							</Link>
							<Link to="/about" className='my-3 mx-2' style={{ textDecoration: "none" }} title='About Us'>
								<span className={`navbar-link ${pathname === '/about' ? "active" : ""}`} >
									About
								</span>
							</Link>
							<Link to="/faq" className='my-3 mx-2' style={{ textDecoration: "none" }} title='FAQ'>
								<span className={`navbar-link ${pathname === '/faq' ? "active" : ""}`} >
									FAQ
								</span>
							</Link>

							<Link to="/contact" className='my-3 mx-2' style={{ textDecoration: "none" }} title='Contact'>
								<span className={`navbar-link ${pathname === '/contact' ? "active" : ""}`} >
									Contact
								</span>
							</Link>
						</Nav>
					</Offcanvas.Body>
				</Navbar.Offcanvas>


				<div className="container-fluid px-1">
					<Row >
						{/* Burger menu */}
						<Col xs={3} sm={2} md={2} className="d-xl-none d-lg-none">
							<Navbar.Toggle aria-controls='offcanvasNavbar-expand-lg' />
						</Col>

						{/* Seachbox on Desktop */}
						<Col md={6} lg={6} xl={8} className="d-none d-md-block p-0 ps-4 m-0" >
							<Select
								showSearch
								allowClear
								size="large"
								className="w-100"
								placeholder="Seach Products"
								onChange={onSearchSelect}
								filterOption={(input, option) =>
									(option?.children ?? '').toLowerCase().includes(input.toLowerCase()) || (option?.children ?? '') === 'All Products'
								}
							>
								<Option key={"allProducts"} value={''} style={{ fontWeight: 800 }}>
									All Products
								</Option>
								{generateSearchOptions()}
							</Select>
						</Col>

						{/* CONTAINER - Fav, Cart, Login  */}
						<Col xs={9} sm={10} md={4} lg={6} xl={4} className='d-flex justify-content-end'>
							<Link to='/favourites' style={{ textDecoration: "none" }} title='Favourite Items'>
								<button className='rounded me-1 px-4 neomorphic-button'>
									{favourite.length > 0 ? (
										<>
											<MdOutlineFavorite className='me-1' style={{ color: "var(--pink)" }} />
											<span style={{ color: "var(--pink)", textDecoration: "none" }}>{favourite.length}</span>
										</>
									) : (
										<>
											<MdOutlineFavoriteBorder className='me-2 text-secondary' />
											<span className='text-secondary' style={{ textDecoration: "none" }}>
												{favourite.length}
											</span>
										</>
									)}
								</button>
							</Link>


							{/* Cart */}
							<Link to='/cart' style={{ textDecoration: "none" }} title='Cart Items'>
								<button className='rounded ms-1 px-4 neomorphic-button'>
									{cart.length > 0 ? (
										<>
											<MdShoppingCart className='me-1' style={{ color: "var(--navy-blue)" }} />
											<span style={{ color: "var(--navy-blue)", textDecoration: "none" }}>{cart.length}</span>
										</>
									) : (
										<>
											<MdOutlineShoppingCart className='me-1 text-secondary' />
											<span className='text-secondary' style={{ textDecoration: "none" }}>
												{cart.length}
											</span>
										</>
									)}
								</button>
							</Link>

							{user.loggedIn ? (
								<Dropdown menu={menuProps}>
									<Button shape='circle' size='large' className='d-flex align-items-center ms-3' icon={<img src={user.photoURL} onError={(e) => e.target.src = '/util/floating_whatsapp.png'} alt={user.name} style={{ width: "100%", height: "100%", borderRadius: "50%" }} />} />
								</Dropdown>
							) : (
								<div id='navbar-signin-btn'>
									<Button onClick={() => Login(setUser)} shape='round' className="ms-2" type='dashed' size='large'>
										<FcGoogle className='me-3' /> Login
									</Button>
								</div>
							)}
						</Col>

						{/* Seachbox on Mobile */}
						<Col xs={12} sm={12} className="d-xl-none d-lg-none d-md-none d-lg-none mt-3">
							<Select
								showSearch
								allowClear
								className="w-100"
								placeholder="Seach Products"
								onChange={onSearchSelect}
								filterOption={(input, option) =>
									(option?.children ?? '').toLowerCase().includes(input.toLowerCase()) || (option?.children ?? '') === 'All Products'
								}
							>
								<Option key={"allProducts"} value={''} style={{ fontWeight: 800 }}>
									All Products
								</Option>
								{generateSearchOptions()}
							</Select>
						</Col>
					</Row>
				</div>
			</Container>
		</Navbar >
	);
}

export default NavBar;
