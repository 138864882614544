// React Components
import { useState, useEffect } from "react";
import { lazy, Suspense } from 'react';
// Custom Components
import HeaderAnnouncement from "./components/UtilityComponents/HeaderAnnouncement";
import NavBar from "./components/Header/NavBar";
import Router from "./Router";
import { monitorAuthenticationState } from "./components/UtilityComponents/Firebase";
// Contexts
import FavouriteContext from "./contexts/FavouriteContext";
import CartContext from "./contexts/CartContext";
import CategoryContext from "./contexts/CategoryContext";
import UserContext from "./contexts/UserContext";
// Libraries
import { BrowserRouter } from "react-router-dom";
import { message } from "antd";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { useLocation } from "react-router-dom";
import SugandhaArtsDetails from "./_data/SugandhaArtsDetails";

const AppFooter = lazy(() => import('./components/Footer/Footer'));

// const ScrollToTop = ({ children }) =>
// {
// 	const { pathname } = useLocation();

// 	useEffect(() =>
// 	{
// 		window.scrollTo(0, 0);
// 	}, [pathname]);

// 	return children;
// };

function App()
{
	const [cart, setCart] = useState([]);
	const [cartValue, setCartValue] = useState(0);
	const [favourite, setFavourite] = useState([]);
	const [messageApi, contextHolder] = message.useMessage();
	const [category, setCategory] = useState("*");
	const [user, setUser] = useState({ loggedIn: false, email: "", photoURL: "", name: "" });

	// useEffect(() =>
	// {
	// 	if (user.loggedIn) showMessage("Welcome " + user.name, "info");
	// }, [user]);

	useEffect(() =>
	{
		// 1. Persistent login
		monitorAuthenticationState(setUser);

		// 2. load Cart and Favourite from localStorage
		const cartFromLocalStorage = JSON.parse(localStorage.getItem("cart"));
		const favouriteFromLocalStorage = JSON.parse(localStorage.getItem("favourite"));

		if (cartFromLocalStorage)
		{
			setCart(cartFromLocalStorage);
		}
		if (favouriteFromLocalStorage)
		{
			setFavourite(favouriteFromLocalStorage);
		}
	}, []);

	useEffect(() =>
	{
		reCalculateCartValue();
	}, [cart]);

	function showMessage(message, type)
	{
		messageApi[type](message);
	}

	const addToCart = (item) =>
	{
		const isDuplicate = cart.some((cartItem) => cartItem.id === item.id);
		if (!isDuplicate)
		{
			item.quantity = 1;
			const updatedCart = [...cart, item];
			setCart(updatedCart);
			localStorage.setItem("cart", JSON.stringify(updatedCart));
			showMessage("Added to cart", "success");
		}
	};

	const removeFromCart = (item) =>
	{
		const updatedCart = cart.filter((cartItem) => cartItem.id !== item.id);
		localStorage.setItem("cart", JSON.stringify(updatedCart));
		setCart(updatedCart);
		reCalculateCartValue();
	};

	const updateQuantity = (item, quantity) =>
	{
		const updatedCart = cart.map((cartItem) =>
		{
			if (cartItem.id === item.id)
			{
				cartItem.quantity = quantity;
			}
			return cartItem;
		});
		localStorage.setItem("cart", JSON.stringify(updatedCart));
		setCart(updatedCart);
		reCalculateCartValue();
	};

	const reCalculateCartValue = () =>
	{
		let total = 0;
		cart.forEach((cartItem) =>
		{
			total += cartItem.price * cartItem.quantity;
		});
		setCartValue(total);
	};

	const addToFavourite = (item) =>
	{
		const isDuplicate = favourite.some((favouriteItem) => favouriteItem.id === item.id);
		if (!isDuplicate)
		{
			setFavourite([...favourite, item]);
			localStorage.setItem("favourite", JSON.stringify([...favourite, item]));
		}
	};

	const removeFromFavourite = (item) =>
	{
		const updatedFavourite = favourite.filter((favouriteItem) => favouriteItem.id !== item.id);
		setFavourite(updatedFavourite);
		localStorage.setItem("favourite", JSON.stringify(updatedFavourite));
	};

	return (
		<>
			<BrowserRouter>
				<UserContext.Provider value={{ user, setUser }}>
					<CartContext.Provider value={{ cart, setCart, cartValue, setCartValue, updateQuantity, addToCart, removeFromCart }}>
						<FavouriteContext.Provider value={{ favourite, addToFavourite, removeFromFavourite }}>
							<CategoryContext.Provider value={{ category, setCategory }}>
								<HeaderAnnouncement />
								<NavBar />
								<Router />
								<Suspense fallback={<></>}>
									<AppFooter />
								</Suspense>
							</CategoryContext.Provider>
						</FavouriteContext.Provider>
						{contextHolder}
					</CartContext.Provider>
					<FloatingWhatsApp phoneNumber={SugandhaArtsDetails.whatsapp} accountName={SugandhaArtsDetails.name} avatar='/util/floating_whatsapp.png' notification={false} statusMessage="Typically replies instantly" buttonClassName='floating-whatsapp' />
				</UserContext.Provider>
			</BrowserRouter>
		</>
	);
}

export default App;
