// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, set, child, get } from "firebase/database";
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut, setPersistence, browserLocalPersistence, onAuthStateChanged } from "firebase/auth";
const FIREBASE_CONFIG = {
	apiKey: "AIzaSyDvx5cM8gyCi78Nv4ZvdUqsRkVD40RwKto",
	authDomain: "sugandhaarts-27ef5.firebaseapp.com",
	projectId: "sugandhaarts-27ef5",
	storageBucket: "sugandhaarts-27ef5.appspot.com",
	messagingSenderId: "411550910884",
	appId: "1:411550910884:web:0fd1fb1baed86467b3d931",
	measurementId: "G-ZJ3CJ6V2LE",
	databaseURL: "https://sugandhaarts-27ef5-default-rtdb.asia-southeast1.firebasedatabase.app/",
};

// Initialize Firebase
const app = initializeApp(FIREBASE_CONFIG);
const provider = new GoogleAuthProvider();
const auth = getAuth(app);

export const writeOrderOnFirebase = (itemsList, totalAmount, appliedCoupon, shippingAddress, phoneNumber) =>
{
	return new Promise((resolve, reject) =>
	{

		try
		{
			const billId = Math.round(Date.now() / 1000);
			const db = getDatabase();
			const billRef = ref(db, "bills/" + billId);

			set(billRef, {
				items: itemsList,
				price: totalAmount,
				coupon: !appliedCoupon ? "" : appliedCoupon,
				address: shippingAddress,
				uid: auth.currentUser.uid,
				phone: phoneNumber,
				name: auth.currentUser.displayName,
			})
				.then(() =>
				{
					console.log("Bill data was successfully set.");
					resolve(billId); // Resolve the promise with the billId
				})
				.catch((error) =>
				{
					console.log("Error setting bill data:", error);
					resolve(error); // Reject the promise with the error
				});
		} catch (error)
		{
			console.log("Error setting bill data:", error);
			resolve(error); // Reject the promise with the error
		}
	});
};


export const readPrevBills = async (userId) =>
{
	console.log("reading from firebase for user: " + userId);
	const dbRef = ref(getDatabase());
	try
	{
		const snapshot = await get(child(dbRef, `receipts/${userId}`));
		if (snapshot.exists())
		{
			return snapshot.val();
		} else
		{
			return null;
		}
	} catch (error)
	{
		console.error(error);
		return null;
	}
};

export const fetchUnpaidBillIds = async () =>
{
	console.log("Fetching unpaid billIds from Firebase");
	const dbRef = ref(getDatabase());
	const unpaidBillIds = [];

	try
	{
		const snapshot = await get(dbRef);
		if (snapshot.exists())
		{
			// Iterate through all bill nodes under the root

			console.log("Unpaid Bill IDs:", snapshot.val());
			return unpaidBillIds;
		} else
		{
			console.log("No bills found in the database.");
			return [];
		}
	} catch (error)
	{
		console.error("Error fetching unpaid billIds:", error);
		return [];
	}
};

export const Logout = (setUser) =>
{
	const auth = getAuth();

	signOut(auth)
		.then(() =>
		{
			setUser({ loggedIn: false });
		})
		.catch((error) =>
		{
			alert("Error Signing out", error.message);
		});
};

export const Login = (setUser) =>
{
	const auth = getAuth();

	setPersistence(auth, browserLocalPersistence)
		.then(async () =>
		{
			try
			{
				const result = await signInWithPopup(auth, provider);
				const credential = GoogleAuthProvider.credentialFromResult(result);
				const token = credential.accessToken;
				const email = result.user.email;
				const photoURL = result.user.photoURL;
				const name = result.user.displayName;
				const uid = result.user.uid;
				setUser({ loggedIn: true, email: email, photoURL: photoURL, name: name, uid: uid });
			} catch (error)
			{
				// Handle Errors here.
				const errorCode = error.code;
				const errorMessage = error.message;
				// The email of the user's account used.
				const email_1 = error.customData.email;
				// The AuthCredential type that was used.
				const credential_1 = GoogleAuthProvider.credentialFromError(error);
				alert("Error Logging in", errorMessage);
			}
		})
		.catch((error) =>
		{
			const errorCode = error.code;
			const errorMessage = error.message;
		});
};

export const monitorAuthenticationState = (setUser) =>
{
	const auth = getAuth();
	onAuthStateChanged(auth, (user) =>
	{
		if (user)
		{
			const email = user.email;
			const photoURL = user.photoURL;
			const name = user.displayName;
			const uid = user.uid;
			setUser({ loggedIn: true, email: email, photoURL: photoURL, name: name, uid: uid });
		} else
		{
			setUser({ loggedIn: false });
		}
	});
};
