import "./HeaderAnnouncement.css";
import { MdOutlineMail, MdCall } from "react-icons/md";
import SugandhaArtsDetails from "../../_data/SugandhaArtsDetails";
function HeaderAnnouncement() {
	return (
		<div className='d-flex flex-column flex-sm-row banner'>
			<span className='mx-3' onClick={() => (window.location = `mailto: ${SugandhaArtsDetails.email}`)} style={{ cursor: "pointer" }}>
				<MdOutlineMail className='me-2' />
				{SugandhaArtsDetails.email}
			</span>

			<span className='ms-3' onClick={() => (window.location = `tel: +91${SugandhaArtsDetails.phone}`)} style={{ cursor: "pointer" }}>
				<MdCall className='me-2' />
				{SugandhaArtsDetails.phone}
			</span>
		</div>
	);
}

export default HeaderAnnouncement;
